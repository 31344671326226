import { useState } from "react";
import Navbar from "./scenes/Navbar";
import Landing from "./scenes/Landing";
import LineGradient from "./components/LineGradient";
import MySkills from "./scenes/MySkills";
import Projects from "./scenes/Projects";
import Footer from "./scenes/Footer";
import Testimonials from "./scenes/Testimonials";
import Contact from "./scenes/Contact";



function Main() {
  const [selectedpage, setSelectedPage] = useState("home");
  const [isTopOfPage, setIsTopOfPage] = useState(false);

  return (
    // <BrowserRouter> 
    <div className="app bg-deep-blue">
      <Navbar
        isTopOfPage={isTopOfPage}
        selectedpage={selectedpage}
        setSelectedPage={setSelectedPage}
      />

      <div className="w-5/6 mx-auto md:h-full ">
        <Landing
          selectedpage={selectedpage}
          setSelectedPage={setSelectedPage}
        ></Landing>
      </div>

      <LineGradient />
      {/* Skills div  */}
      {/* w-5/6 is 83% width  */}
      <div className="w-5/6 mx-auto md:h-full">
        <MySkills />
        <Projects />
        <Testimonials />
        <Contact />
        <Footer />

        {/* <ComingSoon /> */}
      </div>
    </div>
    // </BrowserRouter>
  );
}

export default Main;
