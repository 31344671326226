import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-52 bg-black text-white pt-5">
      <div className="w-10/12 mx-auto text-white">
        <SocialMediaIcons />

        <div className="md:flex justify-center  md:justify-between text-center">
          <p className="font-playfair font-semibold text-2xl">
            SURAJ BAHADUR
          </p>

          <p className="font-playfair text-md">
            ©2024 Suraj Bahadur. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
