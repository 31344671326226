import { BrowserRouter , Route, Routes} from "react-router-dom";
import Main from "./Main";
import Hero from "./scenes/ComingSoon"; 

function App() {

  return (
    <BrowserRouter>
      {/* <div className="app">
        <Main />
      </div> */}

      <Routes>
      <Route path='/' element={<Main/>} ></Route>
      <Route path='/courses' element={<Hero/>}></Route>

      </Routes>
      
    </BrowserRouter>
  );
}

export default App;
