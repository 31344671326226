const SocialMediaIcons = () => {
    return (
      <div className="flex justify-center md:justify-start my-10 gap-7">
        <a
          
          className="hover:opacity-50 transition duration-500"
          href="https://www.linkedin.com/in/suraj-bahadur-kumal/"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="linkedin" src="https://img.icons8.com/color/32/null/linkedin-circled--v1.png"/>
        </a>
        
        <a
          className="hover:opacity-50 transition duration-500"
          href="https://github.com/surajbahadur591"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="github" src="https://img.icons8.com/nolan/32/github.png"/>
        </a>
        <a
          className="hover:opacity-50 transition duration-500"
          href="https://www.instagram.com/surajbahadur58/"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="instagram" src="https://img.icons8.com/fluency/32/null/instagram-new.png"/>
        </a>

        <a
          className="hover:opacity-50 transition duration-500"
          href="https://www.youtube.com/@SurajBahadur"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="youtube" src="https://img.icons8.com/color/32/null/youtube-play.png"/>
        </a>
      </div>
    );
  };
  
  export default SocialMediaIcons;