import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Project = ({ title, description }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`;

  return (
    <motion.div variants={projectVariant} className="relative mx-2 my-2">
      <div className={overlayStyles}>
        <p className="text-2xl font-playfair text-black">{title}</p>
        <p className="mt-7">
          <a target="_blank" rel="noreferrer" href={`${description}`}>
            {description}
          </a>{" "}
        </p>
      </div>

      <img
        src={`https://itsmesuraj.s3.ap-south-1.amazonaws.com/${title}.webp`}
        alt={title}
      />
    </motion.div>
  );
};

const Projects = () => {
  return (
    <section id="projects" className="py-24">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-black text-4xl">
            <span className="text-red">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        {/* <p className="mt-10 mb-10 text-black">Have a look at my works!!</p> */}
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* ROW 1 */}
          <div
            className="flex justify-center text-center items-center p-10 mx-auto bg-red
              max-w-[400px] w-full max-h-[400px] text-2xl font-playfair font-semibold"
          >
            BEAUTIFUL USER INTERFACES
          </div>

          <Project
            title="Subsifynow-NextJS-Node"
            description="https://subsifynow.com/"
          />

          <Project
            title="Leverauto-Enterprise-App"
            description="https://www.leverauto.com/"
          />

          {/* ROW 2 */}
          {/* <Project
            title="full-stack-app-changelog"
            description="https://changelog-frontend.netlify.app/"
          /> */}
          <Project
            title="Saas-Membership-App"
            description="https://saas-membership.netlify.app/"
          />

          <Project
            title="react-project-pet-adoption"
            description="https://react-project-pet-adoption.netlify.app/"
          />
          <Project
            title="restaurant-landing-page-react-tailwind"
            description="https://tech-arion-task.netlify.app/"
          />

          {/* ROW 3 */}

          {/* <Project
            title="React-Advanced-Form-Validation"
            description="https://react-forms-client.netlify.app/"
          /> */}
          <Project
            title="React-Project-Youtube-Clone"
            description="https://react-project-youtube.netlify.app/"
          />
          <Project
            title="React-Tailwind-Landing-Page"
            description="https://react-tailwindcss-landing-page.netlify.app/"
          />
          <div
            className="flex justify-center text-center items-center p-10 mx-auto bg-blue
              max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            SMOOTH USER EXPERIENCE
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
